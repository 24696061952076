.btn-xs {
    padding  : .25rem .4rem;
    font-size  : .875rem;
    line-height  : .5;
    border-radius : .2rem;
}

.form-switch .form-check-input{
    cursor: pointer;
}
.box-shadow{
    padding: 8px 5px;
    box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.modal-large {
    width: 90vw ;   /* Occupy the 90% of the screen width */
    max-width: 90vw;
}